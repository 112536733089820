import React from "react"
import * as styles from "./call-to-action.module.scss"
import PropTypes from "prop-types"

function CallToActionHeader(props) {
  return (
    <div className={styles.header_container}>
      <div className={styles.header_bar}></div>
      <h1>{props.text}</h1>
      <div className={styles.header_bar}></div>
    </div>
  )
}

CallToActionHeader.propTypes = {
  text: PropTypes.string.isRequired
}

export default CallToActionHeader
