import React, { useEffect, useState, useRef, useCallback } from "react"
import * as styles from "./homepage-blog.module.scss"
import HomepageBlog from "./homepage-blog"
import { useStaticQuery, graphql } from "gatsby"
import { DESKTOP_EX } from "../../constants"

function BlogSummary() {
  const refContainer = useRef(0)

  const [isScrolling, setScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [rightPadding, setRightPadding] = useState("")

  const handleDown = useCallback((e) => {
    setScrolling(true)
    setClientX(e.clientX)
  }, [])

  const scrolledRightAdjustment = useCallback(() => {
    if (styles.right_scroll_adjustment !== rightPadding) {
      setRightPadding(styles.right_scroll_adjustment)
    }
  }, [rightPadding])

  const scrolledLeftAdjustment = () => {
    if (styles.right_scroll_adjustment !== "") {
      setRightPadding("")
    }
  }

  useEffect(() => {
    refContainer.current.scrollLeft = -scrollLeft
  })

  const handleUp = useCallback((e) => {
    setScrolling(false)
  }, [])

  const handleMove = useCallback(
    (e) => {
      if (isScrolling) {
        const next = scrollX + e.clientX - clientX

        if (next < scrollX) {
          scrolledRightAdjustment()
        } else {
          scrolledLeftAdjustment()
        }

        setScrollX(next)
        setScrollLeft(next)
        setClientX(e.clientX)
      }
    },
    [isScrolling, scrollX, clientX, scrolledRightAdjustment]
  )

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsBlog(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            data {
              date
              title {
                text
              }
              title_image {
                alt
                gatsbyImageData(width: 200, layout: FIXED)
              }
            }
          }
        }
      }
    }
  `)

  const gatsbySafeDocCheck =
    typeof window === `undefined`
      ? 0
      : document.body.getBoundingClientRect().width
  const [width, setWidth] = useState(gatsbySafeDocCheck)

  useEffect(() => {
    const resizeListener = () => {
      const gatsbySafeDocCheck =
        typeof window === `undefined`
          ? 0
          : document.body.getBoundingClientRect().width
      setWidth(gatsbySafeDocCheck)
    }

    window.addEventListener("resize", resizeListener)
    resizeListener()
    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  })

  const numBlogs = width < DESKTOP_EX ? 2 : 5

  return (
    <textbox
      className={`${styles.blogs_container} ${rightPadding}`}
      ref={refContainer}
      onMouseDown={handleDown}
      onMouseMove={handleMove}
      onMouseUp={handleUp}
    >
      <div role="list" className={styles.blogs}>
        {query.allPrismicBoggsBlog.edges.map((obj, index) => {
          while (index <= numBlogs) {
            const { date, title, title_image } = obj.node.data

            return (
              <HomepageBlog
                key={index}
                date={date}
                title={title.text}
                image={title_image.gatsbyImageData}
                imageAlt={title_image.alt}
              />
            )
          }
          return <></>
        })}
      </div>
    </textbox>
  )
}

export default BlogSummary
