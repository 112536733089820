import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./hero-image.module.scss"

function HeroImage() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              home_hero_image {
                alt
                gatsbyImageData
              }
              lara_transparency {
                alt
                gatsbyImageData
              }
              nut_free {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <section className={styles.eye_grab}>
      <div className={styles.desktop}>
        <GatsbyImage
          className={styles.eye_grab_image}
          critical="true"
          alt={data.home_hero_image.alt}
          image={data.home_hero_image.gatsbyImageData}
        />
      </div>
      <div className={styles.mobile}>
        <GatsbyImage
          className={styles.eye_grab_image}
          critical="true"
          alt={data.home_hero_image.alt}
          image={data.home_hero_image.gatsbyImageData}
        />
      </div>
      <div className={styles.eye_grab_text_container}>
        <h1>Our ultimate end must be the creation of the beloved community.</h1>
        <p>- Reverend Dr. Martin Luther King Jr.</p>
      </div>
      <div className={styles.required_images}>
        <div className={styles.top_required_image}>
          <a target="_blank" rel="noreferrer" href="about-us#transparency">
            <div tabIndex={0}>
            <GatsbyImage
              alt={data.lara_transparency.alt}
              image={data.lara_transparency.gatsbyImageData}
            />
            </div>
          </a>
        </div>
        <GatsbyImage
          alt={data.nut_free.alt}
          image={data.nut_free.gatsbyImageData}
        />
      </div>
    </section>
  )
}

export default HeroImage
