import React, { useState } from "react"
import * as styles from "./blogs-and-events.module.scss"
import BlogSummary from "./blog-summary"
import EventsSummary from "./events-summary"

function BlogsAndEvents() {
  const [isEventsCollapsed, eventsCollapsed] = useState(true)
  const [transition, setTransition] = useState(false)
  const toggle = () => {
    setTransition(true)
    setTimeout(() => {
      setTransition(false)
      eventsCollapsed(!isEventsCollapsed)
    }, 750)
  }

  return (
    <section className={styles.container}>
      <EventsSummary
        transition={transition}
        toggle={toggle}
        isEventsCollapsed={isEventsCollapsed}
      />
      <BlogSummary />
    </section>
  )
}

export default BlogsAndEvents
