import React from "react"
import * as styles from "./marketing-copy.module.scss"
import MarketingBlurb, { START, END } from "./marketing-blurb"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function MarketingCopy() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsMarketingBlurbs {
        edges {
          node {
            data {
              marketing_1_image {
                alt
                gatsbyImageData
              }
              marketing_1_text {
                text
              }
              marketing_2_image {
                gatsbyImageData
                alt
              }
              marketing_image_3 {
                alt
                gatsbyImageData
              }
              marketing_link_1 {
                text
              }
              marketing_link_2 {
                text
              }
              marketing_link_3 {
                text
              }
              marketing_text_2 {
                text
              }
              marketing_text_3 {
                text
              }
              marketing_title_1 {
                text
              }
              marketing_title_2 {
                text
              }
              marketing_title_3 {
                text
              }
            }
          }
        }
      }
      paper_plane: file(relativePath: { eq: "paper_plane.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const data = query.allPrismicBoggsMarketingBlurbs.edges[0].node.data
  
  return (
    <section className={styles.container}>
      <div className={styles.marketing_copy}>
        <h1>
          Our mission is to nurture creative, critical thinkers who contribute
          <br />
          to the well-being of their communities.
        </h1>
      </div>
      <div className={styles.marketing_blurb_container}>
        <MarketingBlurb
          topic={data.marketing_title_1.text}
          text={data.marketing_1_text.text}
          justify={END}
          image={data.marketing_1_image.gatsbyImageData}
          imageAlt={data.marketing_1_image.alt}
          link={data.marketing_link_1.text}
        />
        <MarketingBlurb
          topic={data.marketing_title_2.text}
          text={data.marketing_text_2.text}
          image={data.marketing_2_image.gatsbyImageData}
          imageAlt={data.marketing_2_image.alt}
          link={data.marketing_link_2.text}
        />
        <MarketingBlurb
          topic={data.marketing_title_3.text}
          text={data.marketing_text_3.text}
          justify={START}
          image={data.marketing_image_3.gatsbyImageData}
          imageAlt={data.marketing_image_3.alt}
          link={data.marketing_link_3.text}
        />
      </div>
      <div className={styles.background}>
        <div className={styles.paper_plane_container}>
          <GatsbyImage
            tabIndex={-1}
            alt="paper airplane flying behind blurbs"
            image={query.paper_plane.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </section>
  )
}

export default MarketingCopy
