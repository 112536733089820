import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./image-wall.module.scss"

function ImageWall({ images }) {
  let i = 0
  const imageWidgets = []
  for (let j = 0; j < 10; j++) {
    imageWidgets.push(
      <div>
        <GatsbyImage
          className={styles.image}
          alt={images[i].image1.alt}
          image={images[i++].image1.gatsbyImageData}
        />
      </div>
    )
  }

  return <div className={styles.container}>{imageWidgets}</div>
}

export default ImageWall
