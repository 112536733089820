import React from "react"
import * as styles from "./homepage-blog.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { getBlogLink } from "../../blog/blog-itself/blog-utils"
import { getFormattedDate } from "../../utils"

function HomepageBlog({ date, title, image, imageAlt }) {
  const formattedDate = getFormattedDate(date)
  const link = getBlogLink(formattedDate, title)

  return (
    <div className={styles.blog} role="listitem">
      <div className={styles.image_container}>
        <GatsbyImage className={styles.image} alt={imageAlt} image={image} />
      </div>
      <div className={styles.separator}>
        <hr />
      </div>
      <div>
        <h2>{title}</h2>
        <p>{formattedDate}</p>
      </div>
      <div className={styles.read_more}>
        <a href={`/blog#${link}`} aria-label={`Read More about ${title}`}>
          <p className="inline" tabIndex={0}>
            Read more
          </p>
        </a>
      </div>
    </div>
  )
}

HomepageBlog.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
}

export default HomepageBlog
