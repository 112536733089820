import React from "react"
import PropTypes from "prop-types"
import * as styles from "./events.module.scss"
import { months, days } from "../../constants"

function Event({ pos, title, description, date, animating }) {
  const hrStyle = pos <= 3 ? "block" : "none"
  const dateStr = new Date(date).toLocaleDateString("en-US")
  const dateObj = dateStr.split('/')
  const transitionAnimation = animating ? "" : styles.event_container_visible

  return (
    <div className={`${styles.event_container} ${transitionAnimation}`}>
      <div className={`${styles.event} `}>
        <span className={styles.date}>
          {months[dateObj[0] - 1]}
          <br />
          {days[dateObj[1]]}
        </span>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <hr style={{ display: hrStyle }} />
    </div>
  )
}

Event.propTypes = {
  month: PropTypes.string,
  day: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  pos: PropTypes.number,
}

export default Event
