import React from "react"
import * as styles from "./call-to-action.module.scss"
import BragFact from "./brag-fact"
import { useStaticQuery, graphql } from "gatsby"

function BragFacts() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              brag_fact_1 {
                alt
                gatsbyImageData
              }
              brag_fact_2 {
                alt
                gatsbyImageData
              }
              brag_fact_3 {
                alt
                gatsbyImageData
              }
              brag_fact_4 {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div className={styles.brag_facts_container}>
      <BragFact
        text="Responsive Classroom &amp; Restorative Practice Models for School Culture"
        imageAlt={data.brag_fact_1.alt}
        image={data.brag_fact_1.gatsbyImageData}
      />
      <BragFact
        text="Farm-to-School Programming &amp; Onsite Gardens"
        imageAlt={data.brag_fact_2.alt}
        image={data.brag_fact_2.gatsbyImageData}
      />
      <BragFact
        text="8000+ Volume Library"
        imageAlt={data.brag_fact_3.alt}
        image={data.brag_fact_3.gatsbyImageData}
      />
      <BragFact
        text="2.5 Acre Campus"
        imageAlt={data.brag_fact_4.alt}
        image={data.brag_fact_4.gatsbyImageData}
      />
    </div>
  )
}

export default BragFacts
