import React, { useEffect, useState, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Event from "./event"
import DownloadSchedule from "./download-schedule"
import { Link } from "gatsby"
import * as styles from "./events.module.scss"
import { DESKTOP_EX } from "../../constants"

function EventsSummary(props) {
  const [containerStyle, setContainerStyle] = useState(`${styles.collapsed}`)
  const [eventContainerStyle, setEventContainerStyle] = useState(
    styles.events_container_collapsed
  )
  const [readString, setReadString] = useState("more")
  const [animating, setAnimating] = useState(false)
  const [width, setWidth] = useState(500)

  const query = useStaticQuery(graphql`
    query {
      allPrismicBoggsEvents(
        sort: { order: DESC, fields: data___event___date }
      ) {
        edges {
          node {
            data {
              event {
                date
                short_description {
                  text
                }
                title {
                  text
                }
              }
              events_schedule_link {
                url
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsEvents.edges[0].node.data
  const eventsList = data.event
  const scheduleLink = data.events_schedule_link.url

  const toggle = () => {
    const nextContainerStyle =
      containerStyle === styles.collapsed ? styles.expanded : styles.collapsed

    const transitionTime = nextContainerStyle === styles.collapsed ? 100 : 500
    const cssTransitionTime = transitionTime === 100 ? 500 : 100

    const nextEventContainerStyle =
      nextContainerStyle === styles.collapsed
        ? styles.events_container_collapsed
        : styles.events_container_expanded

    const nextReadString =
      nextContainerStyle === styles.collapsed ? "more" : "less"

    setAnimating(true)
    setContainerStyle(nextContainerStyle)
    setTimeout(() => {
      setEventContainerStyle(nextEventContainerStyle)
      setReadString(nextReadString)
    }, transitionTime)

    setTimeout(() => {
      setAnimating(false)
    }, transitionTime + cssTransitionTime)

    props.toggle()
  }

  const resizeListener = useCallback(() => {
    const gatsbySafeDocCheck =
      typeof window === `undefined`
        ? 0
        : document.body.getBoundingClientRect().width

    setWidth(gatsbySafeDocCheck)

    if (width < DESKTOP_EX) {
      setAnimating(false)
    }
  }, [width])

  useEffect(() => {
    window.addEventListener("resize", resizeListener)

    resizeListener()
    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [resizeListener])

  if (width >= DESKTOP_EX) {
    return (
      <div className={containerStyle}>
        <h1>Events</h1>
        <div className={`${styles.events_container} ${eventContainerStyle}`}>
          {eventsList.map((element, index) => {
            if (
              eventContainerStyle.includes(styles.events_container_collapsed) &&
              index >= 2
            ) {
              return <></>
            } else if (index < 6) {
              return (
                <Event
                  key={index}
                  title={element.title.text}
                  description={element.short_description.text}
                  date={element.date}
                  pos={index + 1}
                  animating={animating}
                />
              )
            } else {
              return <></>
            }
          })}
          {/* {eventContainerStyle.includes(styles.events_container_expanded) && (
            <DownloadSchedule
              scheduleLink={scheduleLink}
              animating={animating}
            />
          )} */}
        </div>
        <button onClick={() => toggle()} className={`${styles.read_more}`}>
          <Link>Read {readString}</Link>
        </button>
      </div>
    )
  } else {
    return (
      <div className={styles.collapsed}>
        <h1>Events</h1>
        <div
          className={`${styles.events_container} ${styles.events_container_collapsed}`}
        >
          {eventsList.map((element, index) => {
            if (index >= 3) {
              return <></>
            }

            return (
              <Event
                key={index}
                title={element.title.text}
                description={element.short_description.text}
                date={element.date}
                pos={index + 1}
                animating={animating}
              />
            )
          })}

          {/* <DownloadSchedule scheduleLink={scheduleLink} animating={animating} /> */}
        </div>
      </div>
    )
  }
}

export default EventsSummary
