// extracted by mini-css-extract-plugin
export var background = "marketing-copy-module--background--Sd57F";
export var container = "marketing-copy-module--container--2lkB2";
export var image = "marketing-copy-module--image--uXD35";
export var marketing_blurb = "marketing-copy-module--marketing_blurb--K4Smc";
export var marketing_blurb_container = "marketing-copy-module--marketing_blurb_container--+DNfB";
export var marketing_blurb_end_pos = "marketing-copy-module--marketing_blurb_end_pos--7CnWP";
export var marketing_blurb_start_pos = "marketing-copy-module--marketing_blurb_start_pos--01DaM";
export var marketing_blurb_text = "marketing-copy-module--marketing_blurb_text--a2in4";
export var marketing_copy = "marketing-copy-module--marketing_copy--iVYPE";
export var paper_plane_container = "marketing-copy-module--paper_plane_container--PRdYq";