import React from "react"
import * as styles from "./call-to-action.module.scss"
import PropTypes from "prop-types"
import {GatsbyImage} from "gatsby-plugin-image"

function BragFact(props) {
  return (
    <div className={styles.brag_fact}>
      <div className={styles.brag_image_container}>
        <GatsbyImage alt={props.imageAlt} image={props.image} />
      </div>
      <h2>{props.text}</h2>
    </div>
  )
}

BragFact.propTypes = {
  text: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  fluid: PropTypes.object,
  fixed: PropTypes.object,
}

export default BragFact
