import React from "react"
import * as styles from "./marketing-copy.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

function MarketingBlurb(props) {
  let styleJustify
  switch (props.justify) {
    case START:
      styleJustify = styles.marketing_blurb_start_pos
      break
    case END:
      styleJustify = styles.marketing_blurb_end_pos
      break
    default:
      styleJustify = ""
  }
  return (
    <div className={`${styles.marketing_blurb} ${styleJustify}`}>
      <GatsbyImage
        className={styles.image}
        alt={props.imageAlt}
        image={props.image}
      />
      <div className={styles.marketing_blurb_text}>
        <h2>{props.topic}</h2>
        <p>{props.text}</p>
        <a
          className="light"
          href={props.link}
          aria-label={`Read More about ${props.topic}`}
        >
          <p className="inline" tabIndex={0}>
            Read more
          </p>
        </a>
      </div>
    </div>
  )
}

MarketingBlurb.propTypes = {
  topic: PropTypes.string.isRequired,
  justify: PropTypes.string,
  image: PropTypes.object.isRequired,
}

export default MarketingBlurb
export const START = "start"
export const END = "end"
