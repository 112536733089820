import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/home/hero-image/"
import BlogsAndEvents from "../components/home/blogs-and-events/"
import MarketingCopy from "../components/home/marketing-copy/"
import CallToAction from "../components/home/call-to-action"
import { headerNames } from "../components/navigation/header"
import ImageWall from "../components/image-wall"
import { useStaticQuery, graphql } from "gatsby"

function IndexPage() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageWall {
        edges {
          node {
            data {
              image {
                image1 {
                  gatsbyImageData
                  alt
                }
              }
            }
          }
        }
      }
    }
  `)

  const [_, currentSwapperNullSafety] = useState("")
  const [__, setSwapperNullSafety] = useState(false)

  const images = query.allPrismicBoggsImageWall.edges[0].node.data.image

  return (
    <Layout
      activeHeader={headerNames.home}
      setCurrentSwapperItem={currentSwapperNullSafety}
      setSwapperVisible={setSwapperNullSafety}
    >
      <SEO title="Home" />
      <HeroImage />
      <BlogsAndEvents />
      <ImageWall images={images.slice(0, 10)} />
      <MarketingCopy />
      <ImageWall images={images.slice(10, 20)} />
      <CallToAction />
    </Layout>
  )
}

export default IndexPage
