import React from "react"
import * as styles from "./call-to-action.module.scss"
import CTAHeader from "./call-to-action-header"
import BragFacts from "./brag-facts"
import { navItems } from "../../school-information/school-information-nav-bar"

function CallToAction() {
  return (
    <section className={styles.container}>
      <CTAHeader text={`Community-Based Education Rooted In Love`} />
      <BragFacts />
      <div className={styles.the_cta_container}>
        <a
          target="_self"
          href={`school-information${navItems.applications.link}`}
        >
          <button className={styles.the_cta}>Join Our Community!</button>
        </a>
      </div>
    </section>
  )
}

export default CallToAction
